import {CardsSmoll} from "@/components/card-smoll";
import {CardServiceProps} from "@/components/card-service";

export const lawyerCardTop: CardsSmoll[] = [
    {
        path: "/time.svg",
        alt: "Продать квартирк",
        title: "Печать договоров",
        description: "Подготовим все необходимые документы",
        action:'write'
    }, {
        path: "/maney.svg",
        alt: "Купить квартиру",
        title: "Сопровождение сделок",
        description: "Полное юридическое сопровождение сделки",
        link: 'support'
    }, {
        path: "/search.svg",
        alt: "Сделка",
        title: "Проверка объекта",
        description: "Проверим юридическую чистоту недвижимости",
        action: 'inspection'
    }, {
        path: "/call.svg",
        alt: "Сделка",
        title: "Косультация",
        description: "Бесплатная юридическая консультация",
        link: "tel: +79113007705"
    }]

export const lawyerCardServiceData: CardServiceProps[] = [{
    path: '/time.svg',
    description: "Подготовим договор купли-продажи и акт приема передачи вашей недвижимости.",
    title: "Договора купли-продажи",
    fullText: "После оказания услуги вы получаете договор купли-продажи и акт приема передачи.",
    price: " 3000 рублей",
    action: 'write',
    tag: [
        {text: "Быстрая оформление за 1 час"},
        {text: "Опытные юристы"},
        {text: "Обратим внимание на все нюансы сделки"},
        {text: "Приемлемы цены"},
        {text: "Консультации и поддержка"}
    ]
}, {
    path: '/time.svg',
    description: "Проверка объектов недвижимости на предмет юридической чистоты и отсутствия обременений.",
    title: "Юридическая проверка",
    fullText: "Вы получите отчет о проверке объекта недвижимости в котором будут отражены все риски. ",
    action: 'inspection',
    price: " 7000 рублей",

    tag: [
        {text: "Тщательная проверка"},
        {text: "Защита от юридических рисков"},
        {text: "Подробный отчет о состоянии объекта"},
        {text: "Приемлемы цены"},
        {text: "Опытные юристы"}
    ]
}, {
    path: "/time.svg",
    description: "Сопровождение сделок по купле-продаже недвижимости с полной защитой ваших прав.",
    title: "Сопровождение сделок",
    fullText: "Мы будем с вами от подготовки и проверкм документов до полного расчета по сделке.",
    action: 'support',
    price: " 30 000 рублей",

    tag: [
        {text: "Гарантия защиты прав"},
        {text: "Опытные юристы"},
        {text: "Своевременное оформление"},
        {text: "Подписание документов у нас в офисе"},
        {text: "Индивидуальный подход"}
    ]
}];