import { PageData } from "@/intefaces/page.props";
import { cardObjectData, cardServiceData, cardTop, cards } from "./sale-data";
import { buyCardServiceData, buyCardTop, byuCardObjectData } from "./buy-data";
import {popupData} from "./popup-data";
import {aboutCardServiceData, aboutCardTop} from "./about";
import {lawyerCardServiceData, lawyerCardTop} from "@/data/lawyer-data";
import {cardZemUchastokServiceData, cardZemUchastokTop} from "@/data/zemuchastok-data";
import {cardDolyData, cardDolyTop} from "@/data/doly-data";

export const pages: PageData[] = [{
    id: 'sell',
    title: 'Продать квартиру в Мурманске быстро и выгодно',
    description: 'Получите выгодное предложение. Продайте квартиру без показов и стресса, уже сегодня!',
    btname: 'Продать',
    cardsTop: cardTop,
    cardsService: cardServiceData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
    },{
    id: 'sell-rooms',
    title: 'Продать комнату в Мурманске быстро и выгодно',
    description: 'Получите выгодное предложение. Продайте комнату без показов и стресса, уже сегодня!',
    btname: 'Продать',
    cardsTop: cardTop,
    cardsService: cardServiceData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
    },{
    id: 'sell-doli-v-kvartire',
    title: 'Продать долю в квартире',
    description: 'Получите подробную консультацию. Продайте долю в квартире без показов и стресса, уже сегодня!',
    btname: 'Продать',
    cardsTop: cardDolyTop,
    cardsService: cardDolyData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
    },{
    id: 'zemelniy-uchastok',
    title: 'Продать земельный участок быстро и выгодно',
    description: 'Получите подробную консультацию. Продайте земельный участок в Мурманске или Мурманской области, уже сегодня!',
    btname: 'Продать',
    cardsTop: cardZemUchastokTop,
    cardsService: cardZemUchastokServiceData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
},{
    id: 'buy',
    title: 'Купить квартиру в Мурманске',
    description: 'Полное юридическое сопровождение от поиска квартиры до оформления сделки.',
    btname: 'Купить',
    cardsTop: buyCardTop,
    cardsService: buyCardServiceData,
    cardObject: byuCardObjectData,
    withObjet: cards,
    popUp: popupData,
  },{
    id: 'about',
    title: 'Мурман Клик',
    description: 'С 2011 года помогаем решить жилищный вопрос нашим клиентам.\n' +
        'Мы всегда стремимся обеспечить максимальный комфорт и безопасность на каждом этапе работы',
    btname: 'Задать вопрос',
    cardsTop: aboutCardTop,
    cardsService: aboutCardServiceData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
  },{
    id: 'lawyer',
    title: 'Юридические услуги',
    description: 'Профессиональная помощь в сделках купли-продажи, проверке объектов и юридическом сопровождении. Гарантия качества и защиты прав.',
    btname: 'Консультация',
    cardsTop: lawyerCardTop,
    cardsService: lawyerCardServiceData,
    cardObject: cardObjectData,
    withObjet: cards,
    popUp: popupData,
}];