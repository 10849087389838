import {CardsSmoll} from "@/components/card-smoll";
import {CardServiceProps} from "@/components/card-service";

export const cardDolyTop : CardsSmoll[] = [
    {
        path: "/time.svg",
        alt:"Сделка",
        title:"Продать срочно",
        description:"Купим вашу долю за 1 день",
        action: 'sell'
    },{
        path: "/maney.svg",
        alt:"Сделка",
        title:"Получить аванс",
        description:"Получите аванс уже сегодня",
        action: 'avans'
    },{
        path: "/search.svg",
        alt:"Сделка",
        title:"Online Оценка",
        description:"Узнать стоимость доли",
        action: 'scroll'
    },{
        path: "/call.svg",
        alt:"Сделка",
        title:"Косультация",
        description:"Ответим на все ваши вопросы",
        link: "tel: +79113007705"
    }]


export const cardDolyData: CardServiceProps [] = [{
    path: '/time.svg',
    description: "Купим долю в квартире в течении 24 часов с полным расчетом. Поможем с документами. ",
    title: "Продать долю",
    fullText: "Не нужно менять планы, подстраиваться под остальных собственников квартиры.",
    action: 'sell',
    tag: [
        { text: "Аванс уже сегодня"},
        { text: "Юридическое сопровождение."},
        { text: "Честная оценка." },
        { text: "Полный рассчет в день сделки." },
        { text: "Нет комиссий." },
        { text: "Покупаем с долгами" }
    ]
},{
    path: '/maney.svg',
    description: "Продаете квартиру? Продажа с авансом услуга специально для вас!",
    title: "Получить аванс",
    fullText: "Тип продажи подходит в случае, если необходимо срочно получить деньги в счёт будущей продажи квартиры.",
    action:'avans',
    tag: [
        { text: "Аванс до 1 500 000 руб." },
        { text: "Юридическое сопровождение."},
        { text: "Мы полностью занимаемся продажей" },
        { text: "Рассчет в течении 1 месяца" },
        { text: "Нет комисий" },
    ]
},{
    path: "/doc.svg",
    description: "Хотите получить максимум от продаже своей недвижимости? Мы поможем вам в этом!",
    title: "Продажа с агентом",
    fullText: "Наш специалист оценит Вашу недвижимость и начнёт поиск покупателя по рыночной стоимости.",
    action:'usual-sell',
    tag: [
        { text: "Большая база покупателей"},
        { text: "Юридическое сопровождение."},
        { text: "Объявление о продаже на 50 рекламных площадках" },
        { text: "Нет предоплат и скрытых комиссий" },
        { text: "Комиссия 3%" }
    ]
}];