import {CardsSmoll} from "@/components/card-smoll";
import {CardServiceProps} from "@/components/card-service";

export const cardZemUchastokTop: CardsSmoll[] = [
    {
        path: "/time.svg",
        alt: "Сделка",
        title: "Продать",
        description: "Купим ваш участок за 1 день",
        action: 'sell'
    }, {
        path: "/maney.svg",
        alt: "Сделка",
        title: "Аванс",
        description: "Получите аванс уже сегодня",
        action: 'avans'
    }, {
        path: "/search.svg",
        alt: "Сделка",
        title: "Оценка",
        description: "Узнать стоимость участка",
        action: 'scroll'
    }, {
        path: "/call.svg",
        alt: "Сделка",
        title: "Косультация",
        description: "Ответим на все ваши вопросы",
        link: "tel: +79113007705"
    }]

export const cardZemUchastokServiceData: CardServiceProps [] = [{
    path: '/time.svg',
    description: "Купим ваш участок в течении 24 часов с полным расчетом. Поможем с документами. ",
    title: "Срочная продажа",
    fullText: "Не нужно менять планы, подстраиваться под незнакомых покупателей.",
    action: 'sell',
    tag: [
        {text: "Аванс до 1 500 000 руб."},
        {text: "Юридическое сопровождение."},
        {text: "Честная оценка."},
        {text: "Полный рассчет в день сделки."},
        {text: "Нет комиссий."}
    ]
}, {
    path: '/maney.svg',
    description: "Хотите продать земельный участок но деньги нужны сейчас? Продажа с авансом услуга специально для вас!",
    title: "Аванс уже сегодня ",
    fullText: "Если необходимо срочно получить деньги в счёт будущей продажи земелного участка.",
    action: 'avans',
    tag: [
        {text: "Аванс до 1 500 000 руб."},
        {text: "Юридическое сопровождение."},
        {text: "Полностью занимаемся продажей"},
        {text: "Рассчет в течении 1 месяца"},
        {text: "Нет комисий"},
    ]
}, {
    path: "/doc.svg",
    description: "Хотите получить максимум от продаже своего участка? Мы поможем вам в этом!",
    title: "Продажа с агентом",
    fullText: "Наш специалист оценит Ваш участок и начнёт поиск покупателя по рыночной стоимости.",
    action: 'usual-sell',
    tag: [
        {text: "Большая база покупателей"},
        {text: "Юридическое сопровождение."},
        {text: "50 рекламных площадок"},
        {text: "Вы платите за результат"},
        {text: "Комиссия 3%"}
    ]
}];