import {CardsSmoll} from "@/components/card-smoll";
import {CardServiceProps} from "@/components/card-service";

export const aboutCardTop: CardsSmoll[] = [
    {
        path: "/time.svg",
        alt: "Продать квартиру",
        title: "Продать",
        description: "Поможем продать вашу недвижимость",
        link: "/sell"
    }, {
        path: "/maney.svg",
        alt: "Купить квартиру",
        title: "Купить",
        description: "Найдем лучшее предложение на рынке",
        link: "/buy"
    }, {
        path: "/search.svg",
        alt: "Сделка",
        title: "Наши услуги",
        description: "Весь спектр услуг в сфере недвижимости",
        action: 'scroll'
    }, {
        path: "/call.svg",
        alt: "Сделка",
        title: "Косультация",
        description: "Бесплатная юридическая консультация",
        link: "tel:+ 79113007705"
    }]

export const aboutCardServiceData: CardServiceProps [] = [{
    path: '/time.svg',
    // 250 символов
    description: "Быстро и выгодно продайте вашу недвижимость с нашим уникальным сервисом. Всего за 3 дня c полным расчетом и заключением сделки.",
    title: "Продать недвижимость",
    // 100 символов
    fullText: "Продажа недвижимости без лишних хлопот и задержек.",
    action:'sell',
    tag: [
        // 50 символов
        {text: "Быстрая продажа за 3 дня"},
        {text: "Без комиссий и скрытых платежей"},
        {text: "Помощь квалифицированных юристов"},
        {text: "Минимум встреч и показов"},
        {text: "Подбор оптимальной даты сделки"}
    ]
}, {
    path: '/time.svg',
    description: "Мы предлагаем удобную и быструю покупку недвижимости. Оформление сделки за 3 дня без необходимости ждать покупателей",
    title: "Купить недвижимость",
    fullText: "Поможем купить идеальную недвмжимость.",
    action:'buy',
    tag: [
        {text: "Экономия до 20%"},
        {text: "Полное юридическое сопровождение сделки"},
        {text: "Комиссия 3%"},
        {text: "Большая база объектов"},
        {text: "Анализ инвестиционного потенциала"}
    ]
}, {
    path: "/time.svg",
    description: "Обеспечим полное юридическое сопровождение вашей сделки по купле-продаже недвижимости с гарантией защиты ваших прав",
    title: "Юридические услуги",
    fullText: "Ваш юридический защитник в сфере недвижимости.",
    action:'support',
    tag: [
        {text: "Гарантия защиты прав"},
        {text: "Профессиональные юристы в команде"},
        {text: "Быстрое оформление сделок"},
        {text: "Приемлемые цены"},
        {text: "Персональный подход"}
    ]
}];

    