import {CardServiceProps} from "@/components/card-service";
import {CardsSmoll} from "@/components/card-smoll";

export const cardServiceData: CardServiceProps [] = [{
  path: '/time.svg',
  description: "Купим вашу квартиру в течении 24 часов с полным расчетом. Поможем с документами. ",
  title: "Срочная продажа",
  fullText: "Не нужно менять планы, подстраиваться под незнакомых покупателей и постоянно поддерживать «товарный» вид квартиры.",
    action: 'sell',
  tag: [
    { text: "Аванс до 1 500 000 руб."},
    { text: "Юридическое сопровождение."},
    { text: "Честная оценка." },
    { text: "Полный рассчет в день сделки." },
    { text: "Нет комиссий." }
  ]
},{
  path: '/maney.svg',
  description: "Хотите продать недвижимость но деньги нужны сейчас? Продажа с авансом услуга специально для вас!",
  title: "Аванс уже сегодня ",
  fullText: "Тип продажи подходит в случае, если необходимо срочно получить деньги в счёт будущей продажи квартиры.",
  action:'avans',
  tag: [
    { text: "Аванс до 1 500 000 руб." },
    { text: "Юридическое сопровождение."},
    { text: "Мы полностью занимаемся продажей" },
    { text: "Рассчет в течении 1 месяца" },
    { text: "Нет комисий" },
  ]
},{
  path: "/doc.svg",
  description: "Хотите получить максимум от продаже своей недвижимости? Мы поможем вам в этом!",
  title: "Продажа с агентом",
  fullText: "Наш специалист оценит Вашу недвижимость и начнёт поиск покупателя по рыночной стоимости.",
  action:'usual-sell',
  tag: [
    { text: "Большая база покупателей"},
    { text: "Юридическое сопровождение."},
    { text: "Объявление о продаже на 50 рекламных площадках" },
    { text: "Нет предоплат и скрытых комиссий" },
    { text: "Комиссия 3%" }
  ]
}];

export const cardTop : CardsSmoll[] = [
{
  path: "/time.svg",
  alt:"Сделка",
  title:"Продать срочно",
  description:"Купим вашу недвижимость за 1 день",
  action: 'sell'
},{
  path: "/maney.svg",
  alt:"Сделка",
  title:"Получить аванс",
  description:"Получите аванс уже сегодня",
    action: 'avans'
},{
  path: "/search.svg",
  alt:"Сделка",
  title:"Online Оценка",
  description:"Узнать стоимость квартиры",
  action: 'scroll'
},{
  path: "/call.svg",
  alt:"Сделка",
  title:"Косультация",
  description:"Ответим на все ваши вопросы",
  link: "tel: +79113007705"
}]

export const cards = [
  { title: "Квартира", path: "/icon1.png" },
  { title: "Комната", path: "/icon2.png" },
  { title: "Доли", path: "/icon3.png" },
  { title: "Дом", path: "/icon6.png" },
  { title: "Гараж", path: "/icon-g.png" },
  { title: "Коммерция", path: "/icon4.png" }
];

export const cardObjectData: CardServiceProps [] = [{
  path: "/time.svg",
  description: "Продайте свою квартиру быстро и выгодно с нашей помощью. Мы гарантируем закрытие сделок в кратчайшие сроки и поддержку на каждом шагу.",
  title: "Продать квартиру",
  fullText: "Специализация на продаже квартир с максимальной выгодой для клиента.",
  tag: [
    {text: "Быстрые сделки"},
    {text: "База покупателей"},
    {text: "Юридическая поддержка"},
    {text: "Лучшая цена"}
  ],
  questions:[{
    id: '1',
    question: 'Как быстро продать квартиру?',
    answer: 'Чтобы быстро продать квартиру достаточно оставить заявку на сайте или позвонить нам по телефону 707-705.'
  }, {
    id: '2',
    question: 'Какие гарантии предоставляете?',
    answer: 'Мы работаем в правовом поле и уделяем большое внимание к безопасности сделок, при регистрации сделки мы используем безопасные расчеты или акредетив. Что гарантирует безопасность продажи или покупки недвижимости ?',
  }, {
    id: '3',
    question: 'Есть ли скрытые платежи?',
    answer: 'Цены за наши услуги обсолютно прозрачны: елси мы покупаем у вас недвижимость вы не платите ни чего! если мы помогаем вам ее продать то комиссия составит 3% от стоимости недвижимости.'
  }]
}, {
  path: "/time.svg",
  description: "Продажа комнаты с нами — это гарантия быстрого и надежного закрытия сделки. Отличная возможность для инвесторов и владельцев.",
  title: "Продать комнату",
  fullText: "Оперативная продажа комнат с полной юридической безопасностью.",
  tag: [
    {text: "Быстрая продажа"},
    {text: "Безопасные сделки"},
    {text: "Профессиональный подход"}
  ],
  questions:[{
    id: '4',
    question: 'Каковы особенности продажи комнаты?',
    answer: 'Особенности включают необходимость согласования с соседями.'
  }, {
    id: '5',
    question: 'Как скоро я получу деньги?',
    answer: 'Деньги вы получите в день закрытия сделки.'
  }, {
    id: '6',
    question: 'Можете ли вы помочь с документами?',
    answer: 'Да, мы полностью сопровождаем процесс на всех этапах.'
  }]
}, {
  path: "/time.svg",
  description: "Продайте долю в недвижимости быстро и без риска. Мы обеспечиваем юридическую чистоту и оптимальные условия.",
  title: "Продать долю",
  fullText: "Продажа долей с максимальной выгодой и юридической поддержкой.",
  tag: [
    {text: "Быстрые сделки"},
    {text: "Юридическая чистота"},
    {text: "Лучшие условия"}
  ],
  questions:[{
    id: '7',
    question: 'Что нужно для продажи доли?',
    answer: 'Для начала необходимо согласие всех совладельцев.'
  }, {
    id: '8',
    question: 'Как защитить мои права?',
    answer: 'Мы гарантируем полную защиту ваших прав на каждом этапе.'
  }, {
    id: '9',
    question: 'Как быстро можно продать долю?',
    answer: 'Сделка может быть закрыта в течение нескольких дней.'
  }]
  }, {
  path: "/time.svg",
  description: "Максимизируйте свою прибыль, продавая дом быстро и эффективно. Наша команда поможет оптимизировать процесс для достижения наилучших результатов.",
  title: "Продать дом",
  fullText: "Экспертная помощь в продаже домов, максимальная выгода для вас.",
  tag: [
    {text: "Экспертная помощь"},
    {text: "Высокая выгода"},
    {text: "Безопасность сделок"}
  ],
  questions:[{
    id: '10',
    question: 'Как подготовить дом к продаже?',
    answer: 'Мы предложим рекомендации по улучшению объекта для увеличения его стоимости.'
  }, {
    id: '11',
    question: 'Какие документы потребуются?',
    answer: 'Необходим полный пакет документов на право собственности.'
  }, {
    id: '12',
    question: 'Сколько времени займет продажа?',
    answer: 'Обычно сделка занимает от 3 до 7 дней.'
  }]
  },{
  path: "/time.svg",
  description: "Продайте свой гараж быстро и по выгодной цене. Мы предлагаем простой и надежный процесс продажи с полной поддержкой на всех этапах.",
  title: "Продать гараж",
  fullText: "Надежная продажа гаражей с максимальной выгодой и юридической безопасностью.",
  tag: [
    {text: "Быстрая продажа"},
    {text: "Выгодные условия"},
    {text: "Поддержка 24/7"}
  ],
  questions:[{
    id: '13',
    question: 'Как быстро можно продать гараж?',
    answer: 'Продажа обычно занимает не более недели.'
  }, {
    id: '14',
    question: 'Какие документы нужны для продажи?',
    answer: 'Необходимы документы, подтверждающие право собственности.'
  }, {
    id: '15',
    question: 'Можете ли вы помочь с оценкой гаража?',
    answer: 'Да, мы предоставим услуги оценки для определения оптимальной цены.'
  }]
}, {
  path: "/time.svg",
  description: "Продажа коммерческой недвижимости с максимальной выгодой и минимальными рисками. Мы помогаем на всех этапах, от оценки до заключения сделки.",
  title: "Продать коммерческую недвижимость",
  fullText: "Эффективная помощь в продаже коммерческой недвижимости с фокусом на высокую доходность.",
  tag: [
    {text: "Максимальная выгода"},
    {text: "Полное сопровождение"},
    {text: "Безопасные сделки"}
  ],
  questions:[{
    id: '16',
    question: 'Как подготовить объект к продаже?',
    answer: 'Мы предложим стратегии для увеличения привлекательности вашего объекта.'
  }, {
    id: '17',
    question: 'Каковы основные этапы продажи?',
    answer: 'От оценки и маркетинга до переговоров и закрытия сделки.'
  }, {
    id: '18',
    question: 'Как минимизировать риски при сделке?',
    answer: 'Мы используем проверенные методы юридической защиты и тщательной проверки контрагентов.'
  }]
  }]
  
  
  