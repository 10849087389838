import {CardServiceProps} from "@/components/card-service";
import {CardsSmoll} from "@/components/card-smoll";

export const buyCardServiceData: CardServiceProps [] = [{
    path: "/time.svg",
    // 150 characters
    description: "Организуем поиск идеальной квартиры: от выбора до сделки, гарантируя проверку и безопасность каждого этапа.",
    title: "Поиск квартиры",
    // 150 characters
    fullText: "Начните поиск своей мечты с нами сегодня!",
    action: 'buy',
    // up to 30 characters each
    tag: [
        {text: "Быстрый выбор"},
        {text: "Проверка безопасности"},
        {text: "Сделки без риска"},
        {text: "Экономия времени"},
        {text: "Лучшие предложения"}
    ]
}, {
    path: "/time.svg",
    // 150 characters
    description: "Полное юридическое сопровождение сделок по недвижимости, обеспечивающее безопасность и юридическую чистоту.",
    title: "Сопровождение сделки",
    // 150 characters
    fullText: "Обеспечим безопасность вашей сделки!",
    action: 'support',
    // up to 30 characters each
    tag: [
        {text: "Правовая защита"},
        {text: "Комплексный подход"},
        {text: "Минимизация рисков"},
        {text: "Поддержка 24/7"},
        {text: "Эффективные решения"}
    ]
}, {
    path: "/time.svg",
    // 150 characters
    description: "Гарантируем полную юридическую проверку вашей недвижимости для безопасности и защиты от юридических рисков.",
    title: "Юридическая проверка",
    // 150 characters
    fullText: "Вы получите заключение юриста о статусе объекта и рисках!",
    action: 'inspection',
    // up to 30 characters each
    tag: [
        {text: "Тщательная проверка"},
        {text: "Защита прав"},
        {text: "Исключение споров"},
        {text: "Безопасность сделки"},
        {text: "Профессиональный подход"}
    ]
}];


export const buyCardTop: CardsSmoll[] = [
    {
        path: "/time.svg",
        alt: "Сделка",
        title: "Проверка",
        description: "Проверим юридическую чистоту недвижимсоть",
        action: 'inspection'
    }, {
        path: "/maney.svg",
        alt: "Сделка",
        title: "Ипотека",
        description: "Поможем подбрать самую выгодную программу",
        action: 'mortgage'
    }, {
        path: "/search.svg",
        alt: "Сделка",
        title: "Online Оценка",
        description: "Узнайте динамику цен в Мурманске и области",
        action: 'scroll'
    }, {
        path: "/call.svg",
        alt: "Сделка",
        title: "Косультация",
        description: "Ответим на все ваши вопросы. Звоните...",
        link: "tel:+79113007705"
    }]

export const byuCardObjectData: CardServiceProps [] = [{
    path: "/time.svg",
    description: "Выберите идеальную квартиру с нашей помощью! Мы учитываем все ключевые аспекты, обеспечивая безопасность и удовлетворение от покупки.",
    title: "Купить квартиру",
    fullText: "Наши специалисты помогут вам на каждом этапе покупки квартиры, от подбора объекта до оформления сделки.",
    tag: [
        {text: "Правовая проверка"},
        {text: "Инфраструктура района"},
        {text: "Состояние объекта"},
        {text: "Цена и инвестиционный потенциал"},
        {text: "Соответствие запросам клиента"}
    ],
    questions: [
        {
            id: '1',
            question: 'Какие документы нужны для покупки квартиры?',
            answer: 'Для покупки квартиры необходим паспорт, ИНН, документы о праве собственности продавца, выписка из ЕГРН и договор купли-продажи.',
        }, {
            id: '2',
            question: 'Как проверить юридическую чистоту квартиры?',
            answer: 'Юридическую чистоту проверяем через запросы в ЕГРН, анализ истории собственности и отсутствие обременений и арестов.',
        }, {
            id: '3',
            question: 'Что важно учитывать при выборе района для покупки квартиры?',
            answer: 'Важно оценить инфраструктуру, транспортную доступность, экологию, социальную среду и планы развития района.',
        }
    ]
}, {
    path: "/time.svg",
    description: "Поможем выбрать и купить комнату, учитывая ваш бюджет и требования к расположению и состоянию.",
    title: "Купить комнату",
    fullText: "Инвестируйте в комнату, которая подойдет именно вам!",
    tag: [
        {text: "Бюджет и цена"},
        {text: "Состояние комнаты"},
        {text: "Юридическая чистота"},
        {text: "Расположение"},
        {text: "Совместное проживание"}
    ],
    questions: [
        {
            id: '1',
            question: 'Как проверить законность перепланировки?',
            answer: 'Проверяем законность перепланировки через запрос документов в БТИ и сравнение с актуальным состоянием.',
        }, {
            id: '2',
            question: 'Какие риски при покупке комнаты?',
            answer: 'Основные риски - юридические проблемы с документацией и конфликты с соседями по коммунальной квартире.',
        }, {
            id: '3',
            question: 'Можно ли купить комнату в ипотеку?',
            answer: 'Да, но условия ипотеки зависят от банка и могут быть менее выгодными, чем при покупке квартиры.',
        }
    ]
}, {
    path: "/time.svg",
    description: "Найдем для вас оптимальные предложения по долям в квартирах, сопровождая каждый шаг сделки.",
    title: "Купить долю в квартире",
    fullText: "Защитим ваши интересы при покупке доли!",
    tag: [
        {text: "Проверка документов"},
        {text: "Анализ соседей"},
        {text: "Права на использование"},
        {text: "Расчет доли"},
        {text: "Юридическая поддержка"}
    ],
    questions: [
        {
            id: '1',
            question: 'Что такое выкуп доли?',
            answer: 'Выкуп доли - это приобретение доли в квартире у одного из совладельцев.',
        }, {
            id: '2',
            question: 'Как узнать о наличии долгов?',
            answer: 'Необходимо запросить выписку из ЕГРН и проверить наличие обременений или арестов.',
        }, {
            id: '3',
            question: 'Можно ли продать долю без согласия совладельцев?',
            answer: 'Можно, но совладельцы имеют преимущественное право на покупку.',
        }
    ]
}, {
    path: "/time.svg",
    description: "Поможем найти и приобрести загородный дом, идеально соответствующий вашим предпочтениям и бюджету.",
    title: "Купить загородный дом",
    fullText: "Осуществите мечту о загородном доме!",
    tag: [
        {text: "Безопасность района"},
        {text: "Доступность инфраструктуры"},
        {text: "Юридическая проверка"},
        {text: "Экологическая чистота"},
        {text: "Затраты на обслуживание"}
    ],
    questions: [
        {
            id: '1',
            question: 'Какие проверки нужны перед покупкой?',
            answer: 'Необходимо проверить земельный участок, состояние дома, юридическую чистоту и инфраструктуру.',
        }, {
            id: '2',
            question: 'Какие налоги придется платить?',
            answer: 'Налоги включают налог на недвижимость и землю, размер зависит от региона и площади участка.',
        }, {
            id: '3',
            question: 'Можно ли оформить загородный дом в ипотеку?',
            answer: 'Да, многие банки предлагают ипотечные программы для покупки загородной недвижимости.',
        }
    ]
}, {
    path: "/time.svg",
    description: "Поможем выбрать и купить гараж, соответствующий вашим требованиям к безопасности и расположению.",
    title: "Купить гараж",
    fullText: "Сделайте правильный выбор гаража с нами!",
    tag: [
        {text: "Доступность"},
        {text: "Безопасность"},
        {text: "Стоимость"},
        {text: "Состояние гаража"},
        {text: "Юридическая чистота"}
    ],
    questions: [
        {
            id: '1',
            question: 'Что важно знать перед покупкой гаража?',
            answer: 'Важно проверить юридическую чистоту, состояние гаража, и наличие удобного подъезда.',
        }, {
            id: '2',
            question: 'Как проверить права на гараж?',
            answer: 'Необходимо запросить выписку из ЕГРН и проверить отсутствие обременений и права собственности.',
        }, {
            id: '3',
            question: 'Можно ли купить гараж в кредит?',
            answer: 'Да, некоторые банки предлагают кредитные программы для покупки гаражей.',
        }
    ]
}
    , {
        path: "/time.svg",
        description: "Инвестируйте в коммерческую недвижимость с нашей помощью: от анализа рынка до выбора локации.",
        title: "Купить коммерческую недвижимость",
        fullText: "Увеличьте доходы через правильные инвестиции!",
        tag: [
            {text: "Анализ рыночной стоимости"},
            {text: "Выбор местоположения"},
            {text: "Оценка инвестиционного потенциала"},
            {text: "Юридическая безопасность"},
            {text: "Прогнозируемая рентабельность"}
        ],
        questions: [
            {
                id: '1',
                question: 'Какие факторы влияют на доходность?',
                answer: 'Доходность зависит от местоположения, состояния объекта, спроса и текущего рыночного климата.',
            }, {
                id: '2',
                question: 'Как избежать рисков при покупке?',
                answer: 'Необходима тщательная проверка юридической чистоты, анализ рынка и потенциального дохода от аренды.',
            }, {
                id: '3',
                question: 'Нужно ли учитывать зонирование?',
                answer: 'Да, зонирование определяет возможные виды использования недвижимости и может влиять на её стоимость.',
            }
        ]
    }]
    
    
    